<template>
  <div>
    <v-container class="fill-heigt pt-0" fluid>
       <v-row v-if="currentUser.candidate.province===null">
        <v-col cols="12" md="12" class="text-center">
          <v-alert
            color="primary_text"
            dense
            type="info"
            class="text-center black--text caption mb-3"
            dismissible
            ><span v-html="$t('candidate_complete')" @click="go()"></span
          ></v-alert>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" md="8" class="text-left px-1 mt-1">
          <v-row>
            <div class="text-left black--text pt-7">
              <v-btn
                color="primary"
                style="background: #DAF4FD"
                text
                small
                class="text-capitalize"
              >
                <span class="body-2 black--text font-weight-medium">{{
                  $t("Job_Openings")
                }}</span>
              </v-btn>
            </div>
            <div class="text-left black--text px-2 pt-7">
              <v-btn
                color="primary"
                @click="$emit('viewExpo')"
                text
                small
                class="text-capitalize"
              >
                <span class="body-2 black--text">{{
                  $t("spontaneou_application")
                }}</span>
              </v-btn>
            </div>
            <!-- <a
              class="text-left black--text px-0 pt-7"
              @click="$emit('viewExpo')"
            >
              {{ $t("spontaneou_application") }}
            </a> -->
          </v-row>
        </v-col>

        <v-col cols="12" md="4" class="text-right px-0 mt-2">
          <v-row>
            <div class="px-0 pt-6">
              <vacancy-Filter
                @apply="applyVacancyFilter"
                @clean="applyVacancyFilter"
              />
            </div>
            <v-text-field
              class="px-2 pt-3"
              v-model="textSearch"
              append-icon="mdi-magnify"
              :label="$t('cand_search_position')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-row>
        </v-col> </v-row
    ></v-container>

    <v-divider class="my-1 mb-3"></v-divider>
    <v-row>
      <!-- <pre>
       {{filteredItems}}
     </pre> -->
      <v-col
        cols="12"
        md="3"
        lg="3"
        xl="4"
        v-for="vacancy in filteredItems"
        :key="vacancy.id"
      >
        <v-skeleton-loader
          v-if="$apollo.loading"
          elevation="2"
          v-bind="filteredItems"
          type="image, article"
        ></v-skeleton-loader>
        <vacancy-card
          v-else
          :vacancy="vacancy"
          :apply_cand="false"
          :done="true"
          @view="view"
        />
      </v-col>
    </v-row>
    <share-dialog />
  </div>
</template>

<script>
import { GET_VACANCY_ENTITY_QUERY } from "./../graphql/Query";
import { mapActions, mapGetters } from "vuex";
import VacancyCard from "../../../components/VacancyCard.vue";
import ShareDialog from "../../../components/ShareDialog.vue";
import { statusVacancyMixins } from "@/mixins/status";
import vacancyFilter from "./../../../components/vacancyFilter.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  components: { VacancyCard, ShareDialog, vacancyFilter },
  name: "Home",
  data: () => ({
    Vacancies: [],
    filter: {
      areas: undefined,
      place: undefined,
    },
    appliedFilter: true,
    totalPages: 1,
    textSearch: undefined,
  }),
  mixins: [statusVacancyMixins, replaceSpecialCharsMixins],
  apollo: {
    Vacancies: {
      query: GET_VACANCY_ENTITY_QUERY,
      variables() {
        return { filterVacancy: this.filter };
      },
      fetchPolicy: "no-cache",
    },
  },
  watch: {
    Vacancies: function (val) {
      console.log("Vaga vendo", val);
      if (this.appliedFilter) {
        this.setVacanciesState(val);
        this.appliedFilter = false;
      } else {
        this.setVacancyScrolled(val);
      }
      this.totalPages = val.totalPages;
    },
  },
  computed: {
    ...mapGetters({
      getVacancies: "vacancy/getVacancies",
      currentUser: "auth/getCurrentUser",
    }),
    filteredItems() {
      let resultItems = [...this.getVacancies];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }

      resultItems = resultItems.filter((vacancy) => {
        console.log("this.status: ", this.status(vacancy).text);
        return (
          this.status(vacancy).text !== "Waiting" &&
          this.status(vacancy).text !== "Em espera"
        );
      });
      resultItems = resultItems.filter((v) => v.entity != null);
      return resultItems;
    },
  },

  methods: {
    ...mapActions({
      setVacanciesState: "vacancy/setVacancies",
      setVacancyScrolled: "vacancy/setVacancyScrolled",
    }),
    async applyFilter() {
      this.appliedFilter = true;
      this.fetch();
    },
    async fetch() {
      await this.$apollo.queries.vacancies.refetch();
    },
    applyVacancyFilter(filter) {
      const { areas, place } = filter;
      this.filter = { ...this.filter, areas, place };
      console.log(this.filter);
      this.applyFilter();
    },
    view(vacancy) {
      this.$emit("view", vacancy);
    },
    go(){
      this.$router.push({
        path: `/candidate/candidate/profile`,
      });
    }
  },
  // created () {
  //   this.vacancies.push({
  //     id: 1, title: "Contabilista", province: [ "Boane" ], description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  //   })
  // }
};
</script>