<template>
  <div>
    <v-container class="fill-heigt pt-0" fluid>
       <v-row v-if="currentUser.candidate.province===null">
        <v-col cols="12" md="12" class="text-center">
          <v-alert
            color="primary_text"
            dense
            type="info"
            class="text-center black--text caption mb-3"
            dismissible
            ><span v-html="$t('candidate_complete')" @click="go()"></span
          ></v-alert>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" md="8" class="text-left px-2 mt-1">
          <v-row>
            <!-- <a class="text-left black--text px-2 pt-7"  >
              {{ $t("Job_Openings") }}
            </a> -->
            <!-- <pre>
              {{currentUser}}
            </pre> -->
            <div class="text-left black--text px-0 pt-7 text-decoration-none">
              <v-btn
                color="primary"
                text
                small
                class="text-capitalize"
                @click="$emit('viewExpo')"
              >
                <span class="body-2 black--text">{{ $t("Job_Openings") }}</span>
              </v-btn>
            </div>
            <div class="text-left black--text px-0 pt-7 text-decoration-none">
              <v-btn
                color="primary"
                style="background: #DAF4FD"
                text
                small
                class="text-capitalize"
              >
                <span class="body-2 black--text">{{
                  $t("spontaneou_application")
                }}</span>
              </v-btn>
            </div>
          </v-row>
        </v-col>

        <v-col cols="12" md="4" class="text-right px-0 mt-2">
          <v-row>
            <!-- <div class="px-0 pt-3">
              <vacancy-Filter
                @apply="applyVacancyFilter"
                @clean="applyVacancyFilter"
              />
            </div> -->
            <v-text-field
              class="px-2 pt-3"
              v-model="textSearch"
              append-icon="mdi-magnify"
              :label="$t('search_company')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-row>
        </v-col> </v-row
    ></v-container>

    <v-divider class="my-1"></v-divider>
    <!-- <pre>
      {{ currentUser.candidate.entities }}
    </pre> -->
    <v-row class="">
      <v-col cols="12" md="9">
        <!-- <v-radio-group v-model="jobType" row hide-details class="mt-0">
          <v-radio :label="$t('job')" :value="'job'" hide-details></v-radio>
          <v-radio
            :label="$t('Internship')"
            :value="'Internship'"
            hide-details
          ></v-radio>
        </v-radio-group> -->
      </v-col>
      <v-col cols="12" md="3" class="text-right"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3"> </v-col>
      <v-col cols="12" md="6">
        <br /><br />
        <v-card
          class="mx-auto mt-12 my-6"
          max-height="220"
          color="#fbf9f9"
          elevation="0"
        >
          <v-card-text>
            <v-row justify="space-around" class="pa-2 mx-auto">
              <v-col cols="12" md="12">
                <p class="text-center black--text">
                 {{$t('expo_candidate_text1')}}
                </p>
                <p class="text-center black--text" style="margin-top: -3.4%">
                  {{$t('expo_candidate_text2')}}
                </p>
              </v-col>
              <v-col cols="12" md="12">
                <!-- <p
                  class="text-center black--text"
                  v-if="entity.address"
                  style="margin-top: -8%"
                >
                  {{ entity.address.substring(0, 30) }}
                </p> -->
                <!-- <p v-else v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></p> -->
                <v-row justify="center">
                  <div v-if="!isLoading">
                    <v-btn
                      color="primary"
                      class=""
                      @click="sendCandidancy('job')"
                    >
                      <v-icon small>mdi-send</v-icon>
                      <span class="white--text">{{ $t("job") }}</span>
                    </v-btn>

                    <v-btn
                      color="primary"
                      text
                      class="mx-3"
                      style="background: #DAF4FD"
                      @click="sendCandidancy('Internship')"
                    >
                      <v-icon small>mdi-send</v-icon>
                      <span class="primary--text">{{ $t("Internship") }}</span>
                    </v-btn>
                  </div>
                  <!--  -->
                  <v-btn block loading text v-else></v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />

    <alert-dialog
      :dialog="dialogAlert"
      :message="completeMessage"
      @close="dialogAlert = false"
      @go="go()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { statusVacancyMixins } from "@/mixins/status";
import { API_URL } from "@/api";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import { GET_UNSOLICITED_ENTITY } from "../graphql/Query";
import { SEND_UNSOLICITED_CANDIDANCY } from "../graphql/Mutation";
import AlertDialog from "../../../../entity/components/AlertDialog.vue";
export default {
  components: { SuccessDialog, AlertDialog },
  name: "Home",
  data: () => ({
    apiUrl: API_URL,
    isLoading: false,
    loadingData: true,
    dialogAlert: false,
    completeMessage: "",
    entities: [],
    indexDisebla: [],
    showSuccess: false,
    textSearch: undefined,
    jobType: "job",
    candidancy: {
      jobType: undefined,
      entityId: undefined,
      candidate: undefined,
    },
    success: undefined,
  }),
  mixins: [statusVacancyMixins, replaceSpecialCharsMixins],
  apollo: {
    // entities: {
    //   query: GET_ENTITY_QUERY,
    //   fetchPolicy: "no-cache",
    // },
    getUnsolicitedEntities: {
      query: GET_UNSOLICITED_ENTITY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.getUnsolicitedEntities) {
          this.loadingData = false;
          // data.getUnsolicitedEntities.forEach((element) => {
          //   this.indexDisebla.push(element.entityId);
          // });
        }
      },
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },

  methods: {
    view(vacancy) {
      this.$emit("view", vacancy);
    },
    verifyProfile() {
      let countFieldCand = 0,
        countField = 0;

      for (const field in this.fields) {
        countField += 1;
        var type = typeof this.currentUser.candidate[field];
        if (type === "string") {
          if (this.currentUser.candidate[field] !== "") {
            countFieldCand += 1;
          }
        } else if (
          this.currentUser.candidate[field] &&
          this.currentUser.candidate[field].length > 0
        ) {
          countFieldCand += 1;
        }
      }

      if (countFieldCand === countField) {
        return true;
      } else {
        return false;
      }
    },
    async sendCandidancy(jobType) {
      if (
        this.currentUser.candidate.status &&
        this.currentUser.candidate.province
      ) {
        this.isLoading = true;
        try {
          await this.$apollo.mutate({
            mutation: SEND_UNSOLICITED_CANDIDANCY,
            variables: {
              unsolicitedInput: {
                jobType: jobType,
              },
            },
          });
          this.showSuccess = true;

          if (jobType === "job") {
            this.success = this.$t("send_job");
          } else {
            this.success = this.$t("send_Internship");
          }
        } catch (error) {
          // this.error = formatError(error.message);
          console.log(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.dialogAlert = true;
        if (this.currentUser.candidate.status) {
          if (this.currentUser.candidate.province === null) {
            this.completeMessage = this.$t("complete_profile").replace(
              "province",
              this.$t("complete_profile_province")
            );
          }
        } else {
          this.completeMessage = this.$t("complete_profile").replace(
            "province",
            ""
          );
        }
      }
    },
    go() {
      this.$router.push({
        path: `/candidate/candidate/profile`,
      });
    },
  },
  // created () {

  // }
};
</script>