<template>
  <div>
    <list-home
      v-show="show && !show_expo"
      :show="show"
      @view="view"
      @viewExpo="viewExpo()"
    />

    <div v-if="!show && !show_expo">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn icon color="primary" @click="back()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <view-vacance :vacancy="vacancy" v-show="!show" />
        </v-col>
      </v-row>
    </div>
    <div v-if="show_expo">
      <listEntities @viewExpo="viewExpo()" />
    </div>
  </div>
</template>

<script>
import listHome from "./../components/listHome.vue";
import listEntities from "./../components/listEntities.vue";
import viewVacance from "./../components/VacancyDetailsCard.vue";
export default {
  components: { listHome, viewVacance, listEntities },
  name: "PrivateVacancy",
  data: () => ({
    vacancy: [],
    show: true,
    show_expo: false,
  }),

  computed: {},
  created(){
    localStorage.getItem("loginParams")?localStorage.removeItem("loginParams"):undefined
    this.url = window.location.pathname.split("/").length
    if(this.url ===3){
      this.show_expo = true;
      localStorage.removeItem("loginParams")
    }else{
      this.show_expo = false;
    }
  },
  methods: {
    view(vacancy) {
      this.vacancy = vacancy;
      this.show = false;
    },
    back() {
      this.show = !this.show;
    },
    viewExpo() {
      this.show_expo = !this.show_expo;
      this.back();
    },
  },
};
</script>